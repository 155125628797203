import React from "react"
import PropTypes from "prop-types"
import BackgroundImage from "gatsby-background-image"

const Section = ({
  id,
  container,
  backgroundColor,
  minScreenHeight,
  minHeight,
  children,
  backgroundImage,
  customStyle,
}) => {
  const pageId = `section--${id}`
  const style = { ...customStyle }

  if (backgroundColor.length > 0) {
    style.backgroundColor = backgroundColor
  }

  if (minHeight) {
    style.minHeight = minHeight
  }

  return backgroundImage ? (
    <BackgroundImage
      Tag={"section"}
      fluid={backgroundImage}
      className={pageId}
      style={style}
    >
      <div
        className={`container mx-auto py-12 md:py-16 px-8 flex flex-col h-auto`}
      >
        {children}
      </div>
    </BackgroundImage>
  ) : (
    <section className={pageId} style={style}>
      {container ? (
        <div
          className={`container mx-auto py-12  md:py-16 px-8 flex flex-col h-auto`}
        >
          {children}
        </div>
      ) : (
        <div className="w-full">{children}</div>
      )}
    </section>
  )
}

Section.propTypes = {
  id: PropTypes.string,
  container: PropTypes.bool,
  backgroundColor: PropTypes.string,
  minScreenHeight: PropTypes.bool,
  minHeight: PropTypes.string,
  customStyle: PropTypes.object,
  backgroundImage: PropTypes.array,
}

Section.defaultProps = {
  id: `${parseInt(Math.random() * 100000000)}`,
  container: true,
  backgroundColor: "",
  minScreenHeight: true,
  minHeight: undefined,
  backgroundImage: null,
  customStyle: {},
}

export default Section
